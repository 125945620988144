import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import { Card, Typography, Divider, Box ,Dialog, DialogContent, Collapse} from "@mui/material";
import NavLink from "@mui/material/Link";
import "./Footer.css";
import "../../../../containers/App/App.css";

import Trackme from "../../../../assets/images/track-me.png";
import TrackmeActivated from "../../../../assets/images/track-me/Tracking-Activated.png";
import Emergency from "../../../../assets/images/emergency-help.png";
import AlertActivate from "../../../../assets/images/Alert_Activate.png";
import FamilyChat from "../../../../assets/images/family-chat.png";
import MYREDFOLDER from "../../../../assets/images/MyRedFolderLogo.png";
import TrustedSite from "../../../../assets/images/trusted-site@2x.png";
import MCAFEE from "../../../../assets/images/mcafee secure logo@2x.png";
import GoDaddy from "../../../../assets/images/godaddy logo.png";
import email from "../../../../assets/icons/email-contact-icon.svg";
import phone from "../../../../assets/icons/phone-icon.svg";
import location from "../../../../assets/icons/location-icon.svg";
import constants from "../../../../config/constants";
import { useTranslation } from "react-i18next";
import AlertPNG from "../../../../assets/icons/emergency-alert.png";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
// import CustomButton from "../CustomButton";
import { bindActionCreators } from "redux";
import { connect,useDispatch } from "react-redux";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import * as formatters from "../../../../utils/util";
import {
  getActiveEmergencyAlert,
  getActiveEmergencyAlertData,
  deActivateEmergencyAlertAPI,
  checkIsTracking
} from "../redux/actions";
import TrackMe from "./TrackMe/components/TrackMe";
import title_red_img from "../../../../assets/images/settings/system-branding/title_red.svg"
import title_red_White_img from "../../../../assets/images/settings/system-branding/title_red_white.svg"
import title_gray_img from "../../../../assets/images/settings/system-branding/title_grey.svg"
import RemoveDialog from "../../../../containers/Pages/common/RemoveDialog";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AlertMessage from "../../../../containers/Pages/UserProfile/components/AlertMessage";

const StyledCard = styled(Card)({
  position: "relative",
  maxWidth: 220,
  height: 140,
  border: "1px solid #D8D8D8",
  borderRadius: 10,
  boxShadow: "none",
  "&:hover": {
    border: "1px solid #FF3535",
    boxShadow: "0px 2px 8px #00000033",
  },
});

const MobileStyledCard = styled(Card)({
  position: "relative",
  width: 220,
  height: 140,
  marginTop:'30px',
  border: "1px solid #D8D8D8",
  borderRadius: 10,
  boxShadow: "none",
  "&:hover": {
    border: "1px solid #FF3535",
    boxShadow: "0px 2px 8px #00000033",
  },
});

const ImageOverlay = styled("div")({
  position: "relative",
  overflow: "hidden",
  border: "1px solid #fff",
});

const Image = styled("img")({
  width: "100%",
  display: "block",
  // objectFit: "cover",
  height:"120px"
});

const TextOverlay = styled("div")({
  position: "absolute",
  bottom: 0,
  left: 0,
  background: "#000000BA 0% 0% no-repeat padding-box",
  opacity: 1,
  width: "100%",
  boxSizing: "border-box",
  textAlign: "center",
  padding: "8px 16px",
  color: "#fff",
});

const AlertImage = styled("img")({
  width: "308px",
  maxHeight: "100%",
  cursor:"pointer"
});
const Footer = ({ getActiveEmergencyAlert, deleteEmergecyAlert,emergencyListData, emergencyAlerData, 
  getActiveEmergencyAlertData,deActivateEmergencyAlertAPI,checkIsTracking,trackMeReadResponse }) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const userDetails = window.localStorage.getItem("userInfo");
  const userDetailsObject = JSON.parse(userDetails);

  const [emergencyData, setEmergencyData] = useState(false);
  const [errorDialogue, setErrorDialouge] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null)
  const [openTrackMe, setTrackMe] = useState(false)
  const [emergencyTileImage, setEmergencyTileImage] = useState("")
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [successDialouge, setSuccessDialuge] = useState(false);
  const [alertData, setAlertData] = useState(false);
  const [isTracking, setIsTracking] = useState(false);
  const dispatch = useDispatch();
  const [showDialog,setShowDialog] = useState(false)
  const [isPlanSubscribed,setIsPlanSubscribed] = useState(null)

  const isSubscribed = window.localStorage.getItem("isSubscribed")

  useEffect(() => {
    setIsPlanSubscribed(isSubscribed === 'true')
  },[isSubscribed])


  const isTrack = localStorage.getItem("isTrackingEnabled")? JSON.parse(localStorage.getItem("isTrackingEnabled")): false

  useEffect(() => {
    checkIsTracking();
  }, [])


  useEffect(() => {
    setIsTracking(isTrack)
  }, [isTrack])


  useEffect(() => {
    if (trackMeReadResponse) {
      if (trackMeReadResponse && trackMeReadResponse?.status === 200) {
        setIsTracking(true)
        localStorage.setItem("isTrackingEnabled", true);
      }else{
        localStorage.setItem("isTrackingEnabled", false);
      }
    }
  }, [trackMeReadResponse])

  useEffect(() => {
    const emergencyTileCode = userDetailsObject?.accountPreferences?.emergencyTile;
    const options = [
      { value: 'Code_A', label: 'Title Red', image: title_red_img },
      { value: 'Code_B', label: 'Title Red White', image: title_red_White_img },
      { value: 'Code_C', label: 'Title Gray', image: title_gray_img }
    ];
    const selectedOption = options.find(option => option.value === emergencyTileCode);

    if (selectedOption) {
      const image = selectedOption.image;
      setEmergencyTileImage(image)
    } else {
      console.log("Option not found for the received code");
      setEmergencyTileImage(AlertPNG)

    }

  }, [userDetails])

  const myredfolder = [
    {
      text: t("aboutUs"),
      path: "aboutUs/our-history",
    },
    {
      text: t("pricing"),
      path: "pricing",
    }
    ,
    {
      text: t("contactUs"),
      path: "aboutUs/contact-us",
  
    }
  ]
  const ourServices = [
  
    {
  
      text: t("myRedFolderApp"),
      path: "ourServices/my-mrf-app",
    },
    {
  
      text: t("myRedFolderPlan"),
      path:"ourServices/my-mrf-plan"
    },
    {
  
      text: t("goKitsSupplyKits"),
      path: "ourServices/supply-kits",
    },
  ]
  const knowledgeBase = [
    {
      text: t("disasterResourceLibrary"),
      path: "knowledgeBase/risk-by-location",
    },
    {
      text: t("blog"),
      path: "knowledgeBase/blog",
    }, {
      text: t("faq"),
      path: "knowledgeBase/faq",
    },
    {
      text: t("myREDFOLDERTribe"),
      path: "knowledgeBase/myredfolder-tribe"
    }
  
  ]
 

  const openNewPage = () => {
    setTrackMe(true)
    navigate('/Track-me')
  };
  const emergencyHelplineService = () => {
    navigate('/emergency-services');
}
  const familyChat = () => {
    setShowDialog(true);
    setTimeout(() => {
      setShowDialog(false);
    }, 2000);
  }
  
  const listItems = [
    {
      imageSrc: isTracking ? TrackmeActivated : Trackme,
      imageAlt: isTracking ? "Track me Activated" : "Track me",
      name: isTracking ? "" : t("Location Sharing"),
      icon: null,
      overlay: isTracking ? false : true,
      onClick: openNewPage
    },
    {
      imageSrc: Emergency,
      imageAlt: "Emergency Services Location",
      name: t("emergencyHelpline"),
      icon: <LocationOnIcon style={{height:"15px",marginTop:'5px'}} />,
      overlay: true,
      onClick: emergencyHelplineService
    },
    {
      imageSrc: FamilyChat,
      imageAlt: "FamilyChat",
      name: t("familyChat"),
      icon: null,
      overlay: true,
      onClick: familyChat
    },
  ];
  useEffect(() => {
    getActiveEmergencyAlert();
  }, []);

  useEffect(() => {
    if (emergencyListData && emergencyListData.status === 200) {
      if(emergencyListData && emergencyListData.data && emergencyListData.data.isActive === 'true'){
        getActiveEmergencyAlertData();
      }
    }
  }, [emergencyListData]);


  useEffect(() => {
    if (emergencyAlerData && emergencyAlerData.status === 200) {
      const data = emergencyAlerData.data;
      const userDetails = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;

      const activeUser = data.membersList && data.membersList.filter((row) => row.userId === userDetails.id);
      setAlertData(activeUser[0])
      // console.log(activeUser)
      if(userDetails?.userRoleCode === "owner" ||  userDetails?.userRoleCode === "admin" || activeUser.length > 0){
        setEmergencyData(true)
      }else{
        setEmergencyData(false)
      }

    }
  }, [emergencyAlerData]);

  const checkActivation = () => {
    const member = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    if (
      member.dateOfBirth &&
      formatters.calculateAge(member.dateOfBirth) > 17
    ) {
      navigate("/emergency-alert");
    } else  if(member.dateOfBirth){
      setErrorDialouge(true);
      setErrorMessage("You don't have a access to activate!")
    }else{
      setErrorMessage("Please setup your profile to proceed further")
      setErrorDialouge(true);
    }
  };
  const handleImageClick = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left; 
    const y = event.clientY - rect.top; 
    if (x >= 28 && x <= 125 && y >= 27 && y <= 115) {
      getEmergencyAlert();
    } else if (x >= 127 && x <= 350 && y >= 20 && y <= 120) {
      navigate("/emergency-alert")
    }
  };

  const getEmergencyAlert = () => {
    getActiveEmergencyAlertData();
    const userDetails = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : null;
    if (emergencyData) {

      if (
        userDetails.dateOfBirth &&
        formatters.calculateAge(userDetails.dateOfBirth) > 17
      ) {
        setRemoveDialogOpen(true);
      } else {
        setErrorMessage("You don't have a access to deactivate!")
        setErrorDialouge(true);
      }
    } else {
      setRemoveDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setRemoveDialogOpen(false);
  };
  const onDeleteSave = () => {
    const userDetails = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;
    const payload = {
      "emergencyId": alertData?.id,
      "accountId": alertData?.accountId,
      "updatedBy": userDetails?.id
    }
    deActivateEmergencyAlertAPI(payload);
  };
  useEffect(() => {
    if (
      (deleteEmergecyAlert && deleteEmergecyAlert.status === 200) ||
      (deleteEmergecyAlert && deleteEmergecyAlert.status === 404)
    ) {
      if (deleteEmergecyAlert && deleteEmergecyAlert.status === 200) {
        setSuccessDialuge(true);
      }
      navigate("/home");
      window.location.reload();
      dispatch({
        type: "ADD_EMERGENCY_ALERT",
        payload: null,
      });
      setRemoveDialogOpen(false);
    }
  }, [deleteEmergecyAlert]);



  return (
    <>

    {isPlanSubscribed && <>
      <Grid  className="laptop-view  "
        container
        // style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        sx={{ minHeight: "193px", background: "#FDF6E3" }}
      >
   
        <Grid container  xs={12} md={12} lg={12}
        //  className="footer-container-navbar"
        //  sx={{ display: "flex", justifyContent: "space-between" }}
         >
            <div className="footer-container-navbar emergency-tile" style={{display:'flex', marginTop:'20px', marginBottom:'30px'}}>
          <Grid item xs={12} md={4} lg={4} >
            <div className="emergency-tile-image" >
              {errorDialogue && (
                <Alert
                  sx={{
                    position: "absolute",
                    zIndex: 1000,
                    marginTop: "-100px",
                  }}
                  variant="filled"
                  severity="error"
                  onClose={() => {
                    setErrorDialouge(false);
                    setErrorMessage(null);
                  }}
                >
                  {errorMessage}
                </Alert>
              )}
              {emergencyData === true ? (
                <AlertImage src={AlertActivate} onClick={handleImageClick} alt={"Emergency Alert Activated"} />
              ) : (
                <img
                  src={emergencyTileImage}
                  onClick={() => checkActivation()}
                  id="activate-emergency-alert"
                  style={{
                    maxHeight: "100%",
                    width: "324px",
                  }}
                  alt="alert"
                />
              )}
            </div>
          </Grid>
          <Grid
            item
            justifyContent="space-between" // Adjusted to use justifyContent directly on the container
            sx={{ display: "flex", alignItems: "center" }}
            xs={12}
            md={8}
            lg={8}
          >
            {listItems.map((item, i) => (
              <Grid item xs={12} md={4} lg={4} key={"disaster_" + i} style={{display:'flex', justifyContent:'center'}}>
                <StyledCard onClick={item.onClick} style={{ height:"117px",cursor: item.onClick ? "pointer" : "" }}>
                  <ImageOverlay>
                    <Image src={item.imageSrc} alt={item.imageAlt}  />
                    {item.overlay && (<TextOverlay>
                      <Typography
                        className="font-bold-small"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span>{item.icon}</span>
                        {item.name}
                      </Typography>
                    </TextOverlay>)}
                  </ImageOverlay>
                  <Divider />
                </StyledCard>
              </Grid>
            ))}
          </Grid>
          </div>
        </Grid>
        
      </Grid>

        
      <Grid className="mobile-view"
        container
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        sx={{ minHeight: "193px", background: "#FDF6E3" }}>

        <div className="footer-container-navbar">
          <Grid item container xs={12} md={12} lg={12}
          //  className="footer-container-navbar"
           sx={{ display: "flex", justifyContent: "center", flexDirection:"column", pt:'30px', pb:'40px' }}
          >
            <div style={{  display:'flex', justifyContent:'center'}}>
              {errorDialogue && (
                <Alert
                  sx={{
                    position: "absolute",
                    zIndex: 1000,
                    marginTop: "-100px",
                  }}
                  variant="filled"
                  severity="error"
                  onClose={() => {
                    setErrorDialouge(false);
                    setErrorMessage(null);
                  }}
                >
                  {errorMessage}
                </Alert>
              )}
              {emergencyData === true ? (
                <AlertImage src={AlertActivate} onClick={handleImageClick} alt={"Emergency Alert Activated"} />
              ) : (
                <img
                  src={emergencyTileImage}
                  onClick={() => checkActivation()}
                  id="activate-emergency-alert"
                  style={{
                    maxHeight: "100%",
                    width: "240px",
                  }}
                  alt="alert"
                />
              )}
            </div>
            <Grid item container xs={12} md={12} spacing={2} sx={{display:'flex', flexDirection:'column', justifyContent:"center"  }}>
            {listItems.map((item, i) => (
              <Grid item xs={12} md={12} lg={4} key={"disaster_" + i} sx={{display:'flex', justifyContent:'center', padding:0}}>
                <MobileStyledCard onClick={item.onClick} style={{ height:"117px",cursor: item.onClick ? "pointer" : "" }}>
                  <ImageOverlay>
                    <Image src={item.imageSrc} alt={item.imageAlt}  />
                    {item.overlay && (   <TextOverlay>
                      <Typography
                        className="font-bold-small"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span>{item.icon}</span>
                        {item.name}
                      </Typography>
                    </TextOverlay>)}
                  </ImageOverlay>
                  <Divider />
                </MobileStyledCard>
              </Grid>
            ))}
            </Grid>

          </Grid>
        </div>


      </Grid>
    </>}
     

      <Grid container spacing={2} className="footer__topbar" sx={{mt:'10px', mb:'10px'}}>
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          className="footer-bottom-endcontainer footer-container-navbar"
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <div style={{display:'flex'}}>
            <img
              src={TrustedSite}
              alt="text"
              className="trustedsite-img"
              // style={{ width: "350px", height: "58px" }}
            />
          </div>
          <div  style={{display:'flex'}}>
            <img
              src={MCAFEE}
              alt="text"
              className="mcafee-img"
              // style={{ width: "194px", height: "70px" }}
            />
          </div>
          {/* <div>
            <img
              src={GoDaddy}
              alt="text"
              style={{ width: "297px", height: "70px" }}
            />
          </div> */}
        </Grid>
      </Grid>
      <Grid container className="laptop-view">
      {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
   
        <Grid
          item container
          xs={12}
          md={12}
          lg={12}
          sx={{mt:'15px'}}
          // style={{
            // display: "flex",
            // justifyContent: "space-between",
            // paddingLeft:"15px"
            // marginLeft: "103px",
          // }}
        >
           <div  className="footer-container-navbar" style={{display:'flex', justifyContent:'space-between'}}>
          <div>
            <Box
              id="category-a"
              sx={{ fontSize: "12px", textTransform: "uppercase" }}
            >
              <img
                src={MYREDFOLDER}
                alt="myredfolderlogo"
                id="mrflogo-footer"
              />
            </Box>
            <Box
              component="ul"
              className="list-component"
              aria-labelledby="category-a"
              sx={{ pl: 2 }}
            >
              <li className="li-icon-alignment" style={{ maxWidth: "235px" }}>
                <div>
                  <img
                    src={location}
                    alt="address"
                    style={{ marginLeft: "-4px" }}
                  />
                </div>
                <Typography className="footer-navbar-font">
                  255 Primera Blvd. Ste, 160 Lake Mary, FL 32746
                </Typography>
              </li>
              <li className="li-icon-alignment">
                <div>
                  <img
                    src={phone}
                    alt="phone number"
                    style={{ marginLeft: "-4px" }}
                  />
                </div>
                <Typography className="footer-navbar-font">
                  + (888) 267 9990
                </Typography>
              </li>
              <li className="li-icon-alignment">
                <div>
                  <img
                    src={email}
                    alt="email id"
                    style={{ marginLeft: "-4px" }}
                  />
                </div>
                <Typography className="footer-navbar-font">
                  info@nepta.us
                </Typography>
              </li>
            </Box>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <Box id="category-b">
              <Typography className="footer-services-title primary-font-medium-2">
                {t("MYREDFOLDER")}
              </Typography>
              <hr className="footer-services-title-hr" />
            </Box>
            <Box
              component="ul"
              aria-labelledby="category-b"
              sx={{ pl: 2 }}
              style={{ paddingLeft: "0px" }}
            >
              <li className="li-text">
                <NavLink
                  className="li-text-alignment footer-navbar-font"
                  href={constants.REDIRECTION_URL + "aboutUs/our-history"}
                >
                  {t("aboutUs")}
                </NavLink>
              </li>
              <li className="li-text">
                <NavLink
                  className="li-text-alignment footer-navbar-font"
                  href={constants.REDIRECTION_URL + "pricing"}
                >
                  {t("pricing")}
                </NavLink>{" "}
              </li>
              <li className="li-text">
                <NavLink
                  className="li-text-alignment footer-navbar-font"
                  href={constants.REDIRECTION_URL + "aboutUs/contact-us"}
                >
                  {t("contactUs")}
                </NavLink>
              </li>
            </Box>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <Box id="category-c">
              <Typography className="footer-services-title primary-font-medium-2">
                {t("ourServices")}
              </Typography>
              <hr className="footer-services-title-hr" />
            </Box>
            <Box
              component="ul"
              aria-labelledby="category-c"
              sx={{ pl: 2 }}
              style={{ paddingLeft: "0px" }}
            >
              <li className="li-text">
                <NavLink
                  className="li-text-alignment footer-navbar-font"
                  href={constants.REDIRECTION_URL + "ourServices/my-mrf-app"}
                >
                  {t("myRedFolderApp")}
                </NavLink>
              </li>
              <li className="li-text">
                <NavLink
                  className="li-text-alignment footer-navbar-font"
                  href={
                    constants.REDIRECTION_URL + "ourServices/risk-by-location"
                  }
                >
                  {t("myRedFolderPlan")}
                </NavLink>
              </li>
              <li className="li-text">
                <NavLink
                  className="li-text-alignment footer-navbar-font"
                  href={constants.REDIRECTION_URL + "ourServices/supply-kits"}
                >
                  {t("goKitsSupplyKits")}
                </NavLink>
              </li>
            </Box>
          </div>
          <div style={{ paddingTop: "20px" }}>
            <Box id="category-c">
              <Typography className="footer-services-title primary-font-medium-2">
                {t("knowledgeBase")}
              </Typography>
              <hr className="footer-services-title-hr" />
            </Box>
            <Box
              component="ul"
              aria-labelledby="category-c"
              sx={{ pl: 2 }}
              style={{ paddingLeft: "0px" }}
            >
                <li className="li-text">
                <NavLink
                  className="li-text-alignment footer-navbar-font"
                  href={constants.REDIRECTION_URL + "knowledgeBase/risk-by-location"}
                >
                  {t("disasterResourceLibrary")}
                </NavLink>
              </li>
              <li className="li-text">
                <NavLink
                  className="li-text-alignment footer-navbar-font"
                  href={constants.REDIRECTION_URL + "knowledgeBase/blog"}
                >
                  {t("blog")}
                </NavLink>
              </li>
              <li className="li-text">
                <NavLink
                  className="li-text-alignment footer-navbar-font"
                  style={{ textTransform: "uppercase" }}
                  href={constants.REDIRECTION_URL + "knowledgeBase/faq"}
                >
                  {t("faq")}
                </NavLink>
              </li>
              <li className="li-text">
                <NavLink
                  className="li-text-alignment footer-navbar-font"
                  href={constants.REDIRECTION_URL + "knowledgeBase/myredfolder-tribe"}
                >
                  {t("myREDFOLDERTribe")}
                </NavLink>
              </li>
            </Box>
          </div>
          </div>
        </Grid>
       
        {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
      </Grid>
      {/* footer mobile view */}
      <Grid container  className="mobile-view">
        <Grid item xs={12} md={12} sx={{my:'30px'}}>
      <div  className="footer-container-navbar" style={{display:'flex', flexDirection:'column'}}>
        <div>
            <Box
              id="category-a"
              sx={{ fontSize: "12px", textTransform: "uppercase" }}
            >
              <img
                src={MYREDFOLDER}
                alt="myredfolderlogo"
                id="mrflogo-footer"
              />
            </Box>
            <Box
              component="ul"
              className="list-component"
              aria-labelledby="category-a"
              sx={{ pl: 2 }}
            >
              <li className="li-icon-alignment" style={{ maxWidth: "245px" }}>
                <div>
                  <img
                    src={location}
                    alt="address"
                    style={{ marginLeft: "-4px" }}
                  />
                </div>
                <Typography className="primary-font-normal-2">
                  255 Primera Blvd. Ste, 160 Lake Mary, FL 32746
                </Typography>
              </li>
              <li className="li-icon-alignment">
                <div>
                  <img
                    src={phone}
                    alt="phone number"
                    style={{ marginLeft: "-4px" }}
                  />
                </div>
                <Typography className="primary-font-normal-2">
                  + (888) 267 9990
                </Typography>
              </li>
              <li className="li-icon-alignment">
                <div>
                  <img
                    src={email}
                    alt="email id"
                    style={{ marginLeft: "-4px" }}
                  />
                </div>
                <Typography className="primary-font-normal-2">
                  info@nepta.us
                </Typography>
              </li>
            </Box>
          </div>
          <ExpandableSection title={t("MYREDFOLDER")} subtitle={myredfolder} />
          <ExpandableSection title={t("ourServices")} subtitle={ourServices} />
          <ExpandableSection title= {t("knowledgeBase")} subtitle={knowledgeBase} />
        </div>
      </Grid>
      
      </Grid>
      <Grid container className="footer__bottom  " style={{display:'flex', alignItems:'center'}}>
      <div className="footer-container-navbar  ">
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          className="footer-bottom-endcontainer"
          // style={{ display:"flex", justifyContent: "space-between" }}
        >
          <Box style={{ display: "flex", justifyContent: "flex-start" , marginTop:'10px'}}>
            <Typography
              className=" primary-font-normal-3"
              style={{ textTransform: "uppercase" }}
            >
              {" "}
              <NavLink
                className="faq"
                sx={{
                  textDecoration: "none !important",
                  borderRight: " 1px solid #A2A2A2",
                }}
                href={constants.REDIRECTION_URL + "knowledgeBase/faq"}
              >
                {t("faq")}{" "}
              </NavLink>
            </Typography>
            <Typography className=" primary-font-normal-3">
              <NavLink
                className="faq"
                sx={{
                  textDecoration: "none !important",
                  borderRight: " 1px solid #A2A2A2",
                }}
                href={constants.REDIRECTION_URL + "security"}
              >
                {t("security")}
              </NavLink>
            </Typography>
            <Typography
              className=" primary-font-normal-3"
              style={{ border: "none" }}
            >
              <NavLink
                className="faq"
                sx={{ textDecoration: "none !important" }}
                href={constants.REDIRECTION_URL + "privacy-policy"}
              >
                {t("siteMap")}
              </NavLink>
            </Typography>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", marginTop:'10px' }}>
            <Typography className="copyrights footer-navbar-font footer-bottom-endcontainer">
              {/* {t("copyrightsReservedBy")} */}
              {t("copyrights")}{" "}{`${new Date().getFullYear()}`}{" "}{t("reservedBy")}
              <NavLink
                style={{ textTransform: "uppercase", textDecoration: "none" }}
                href={constants.REDIRECTION_URL + "home"}
              >
                <Typography className="copyright-app footer-navbar-font">
                  {" "}
                  {t("MYREDFOLDER®")}
                </Typography>
              </NavLink>
            </Typography>
          </Box>
        </Grid>
        </div>
        {/* <Grid item xs={1} md={1} lg={1}></Grid> */}
        {openTrackMe && <TrackMe  setTrackMe={setTrackMe}/>}
      </Grid>

      {/* {successDialouge && ( */}
      <Dialog
        open={successDialouge}
        onClose={() => {
          setSuccessDialuge(false);
        }}
        className="small-dialog dialog-box"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogContent className="content-dialog">
          <Typography className="primary-font-normal-4">
          Emergency Alert deactivated successfully 
          </Typography>
        </DialogContent>
      </Dialog>
      {removeDialogOpen && (
        <RemoveDialog
          openDialog={removeDialogOpen}
          onClose={handleDialogClose}
          cardContent={"Are you sure you want to deactivate?"}
          onSave={onDeleteSave}
        />
      )}
      {showDialog && <AlertMessage isOpen={true} onClose={false} message={"This function is under construction"} />}
    </>
  );
};
const ExpandableSection = ({ title, subtitle }) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (

    <Grid container sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", borderBottom: expanded ? "1px solid red" : "1px solid #8B8B8B", py: 2 }} onClick={handleExpand}>
        <Typography sx={{ color: "#2B303E" }} className=' primary-font-medium-2'>
          {title}
        </Typography>

        <Box>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </Box>

      <Collapse in={expanded} >
        {subtitle.map((subtitle, index) => (
          <li className="li-text">
                    <NavLink
                  className="li-text-alignment primary-font-normal-1"
                  href={constants.REDIRECTION_URL + subtitle.path}
                >
                  {subtitle.text}
                </NavLink>
          </li>
        ))}
      </Collapse>

    </Grid>

  );
};

function mapStateToProps(state) {
  return {
    addEmergencyAlert: state.emergencyAlert.addEmergencyAlert,
    emergencyListData: state.emergencyAlert.emergencyList,
    emergencyAlerData: state.emergencyAlert.emergencyListData,
    deleteEmergecyAlert: state.emergencyAlert.deleteEmergecyAlert,
    trackMeReadResponse: state.trackMe.trackMeReadResponse
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getActiveEmergencyAlert,
      getActiveEmergencyAlertData,
      deActivateEmergencyAlertAPI,
      checkIsTracking
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
